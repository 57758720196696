export enum APIPortalTicketsServiceGETItemKeys {
	id = 'ticketId',
	status = 'ticketStatus',
	dateCreated = 'opendate',
	ticketTypeDesc = 'typeDesc',
	ticketDesc = 'openComments',
	ticketPhoto = 'openPhoto',
	stepCurrent = 'stepCurrent',
	steps = 'steps',
	stepsData = 'execStatus',
	ticketPriority = 'classId',
	ticketPriorityColor = 'classColor',
	nextAppointment = 'forecast',
	site = 'siteDesc',
	os = 'items',
	requestedBy = 'userRequest',
	workgroup = 'workgroup',
	nFormDesc = 'originFormDesc',
	nFormPDF = 'originFormPDF',
	relatedAssetType = 'openProductDesc',
	relatedAssetSerial ='openSerialId',
	relatedAssetModel = 'openModelDesc',
	relatedAssetBrand = 'openBrandDesc',
	relatedAssetClassId = 'openSerialClassId',
	relatedAssetClassColor = 'openSerialClassColor',
	justifyComments = 'justifyComments',
	justifyDesc = 'justifyDesc',
	justifyPhoto = 'justifyPhoto',
	comments = 'internalComments',

	isSerialStopped = 'isSerialStopped',
	stoppedDate = 'stoppedDate',
	desiredDate = 'desiredDate',
}

export enum APIPortalTicketsServiceGETItemOSKeys {
	done = 'done',
	type = 'formDesc',
	typePrefix = 'processType',
	serialType = 'productDesc',
	serialModel = 'modelDesc',
	serialId = 'serialId',
	serialClassColor = 'classColor',
	serialClassCode = 'classCode',
	serialClassId = 'classId',
	serialClassAvailable = 'classAvailable',
	serialBrand = 'brandDesc',
	addedAfterBy = 'addedAfterBy',
	serialStatus = 'ctrlStatus',
	serialFormPDF = 'formPDF',

	actionComments = 'actionComments',
	actionPhotoCode = 'actionPhotoCode',
	actionPhotoName = 'actionPhotoName',
	actionPhotoUrl = 'actionPhotoUrl'
}

export enum APIPortalTicketsServiceGETItemStepKeys {
	step = 'stepOrder',
	subSteps = 'execStatusSeq',
}

export enum APIPortalTicketsServiceGETItemSubStepKeys {
	subStep = 'stepOrderSeq',
	desc = 'stepDesc',
	doneDate = 'stepEndDate'
}

export interface APIPortalTicketsServiceGETRoot {
	data: APIPortalTicketsServiceGETItem[];
	status: boolean;
}
export enum APIPortalTicketsServiceGETItemStatus {
	PENDING = 'PENDING',
	PROCESS = 'PROCESS',
	DONE = 'DONE',
	NOT_EXECUTED = 'NOT_EXECUTED',
	REJECTED = 'REJECTED',
	CANCELLED = 'CANCELLED'
}

export interface APIPortalTicketsServiceGETItem {
	[APIPortalTicketsServiceGETItemKeys.id]: string;
	[APIPortalTicketsServiceGETItemKeys.status]: APIPortalTicketsServiceGETItemStatus;
	[APIPortalTicketsServiceGETItemKeys.dateCreated]: string;
	[APIPortalTicketsServiceGETItemKeys.ticketTypeDesc]: string;
	[APIPortalTicketsServiceGETItemKeys.ticketDesc]: string;
	[APIPortalTicketsServiceGETItemKeys.ticketPhoto]: string;
	[APIPortalTicketsServiceGETItemKeys.stepsData]: APIPortalTicketsServiceGETItemStep[];
	[APIPortalTicketsServiceGETItemKeys.stepCurrent]: number;
	[APIPortalTicketsServiceGETItemKeys.steps]: number;
	[APIPortalTicketsServiceGETItemKeys.ticketPriority]: string;
	[APIPortalTicketsServiceGETItemKeys.ticketPriorityColor]: string;
	[APIPortalTicketsServiceGETItemKeys.nextAppointment]: string;
	[APIPortalTicketsServiceGETItemKeys.site]: string;
	[APIPortalTicketsServiceGETItemKeys.requestedBy]: string;
	[APIPortalTicketsServiceGETItemKeys.workgroup]: string;
	[APIPortalTicketsServiceGETItemKeys.os]: APIPortalTicketsServiceGETItemOS[];
	[APIPortalTicketsServiceGETItemKeys.nFormDesc]: string;
	[APIPortalTicketsServiceGETItemKeys.nFormPDF]: string;
	[APIPortalTicketsServiceGETItemKeys.relatedAssetType]: string;
	[APIPortalTicketsServiceGETItemKeys.relatedAssetSerial]: string;
	[APIPortalTicketsServiceGETItemKeys.relatedAssetModel]: string;
	[APIPortalTicketsServiceGETItemKeys.justifyComments]: string;
	[APIPortalTicketsServiceGETItemKeys.justifyDesc]: string;
	[APIPortalTicketsServiceGETItemKeys.justifyPhoto]: string;
	[APIPortalTicketsServiceGETItemKeys.comments]: string;
	[APIPortalTicketsServiceGETItemKeys.relatedAssetBrand]: string;
	[APIPortalTicketsServiceGETItemKeys.relatedAssetClassId]: string;
	[APIPortalTicketsServiceGETItemKeys.relatedAssetClassColor]: string;

	[APIPortalTicketsServiceGETItemKeys.isSerialStopped]: 0 | 1;
	[APIPortalTicketsServiceGETItemKeys.stoppedDate]: string;
	[APIPortalTicketsServiceGETItemKeys.desiredDate]: string;
}

export interface APIPortalTicketsServiceGETItemOS {
	[APIPortalTicketsServiceGETItemOSKeys.done]: boolean;
	[APIPortalTicketsServiceGETItemOSKeys.type]: string;
	[APIPortalTicketsServiceGETItemOSKeys.typePrefix]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialBrand]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialType]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialModel]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialId]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialClassColor]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialClassCode]: 'classCode';
	[APIPortalTicketsServiceGETItemOSKeys.serialClassId]: 'classId';
	[APIPortalTicketsServiceGETItemOSKeys.serialClassAvailable]: 'classAvailable';
	[APIPortalTicketsServiceGETItemOSKeys.addedAfterBy]: string;
	[APIPortalTicketsServiceGETItemOSKeys.serialStatus]: 'PENDING' | 'DONE';
	[APIPortalTicketsServiceGETItemOSKeys.serialFormPDF]: string;

	[APIPortalTicketsServiceGETItemOSKeys.actionComments]: string;
	[APIPortalTicketsServiceGETItemOSKeys.actionPhotoCode]: string;
	[APIPortalTicketsServiceGETItemOSKeys.actionPhotoName]: string;
	[APIPortalTicketsServiceGETItemOSKeys.actionPhotoUrl]: string;
}

export interface APIPortalTicketsServiceGETItemStep {
	[APIPortalTicketsServiceGETItemStepKeys.step]: number;
	doneDate: string;
	[APIPortalTicketsServiceGETItemStepKeys.subSteps]: {
		[APIPortalTicketsServiceGETItemSubStepKeys.subStep]: number;
		[APIPortalTicketsServiceGETItemSubStepKeys.desc]: string;
		[APIPortalTicketsServiceGETItemSubStepKeys.doneDate]?: any;
	}[];
}
